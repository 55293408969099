import { fetch } from '@mindbaz/app-manager';

export const getDatabasestats = async (
  idCompany: number,
  startDate: string,
  endDate: string
) => {
  return fetch({
    method: 'POST',
    url: `${process.env.REACT_APP_API_CORE}safesend/databasestats`,
    data: {
      idCompany,
      startDate,
      endDate,
    },
  }).then((result: any = {}) => {
    return result.data.data || result;
  });
};

export const getdailyStats = async (
  idCompany: number,
  startDate: string,
  endDate: string
) => {
  return fetch({
    method: 'POST',
    url: `${process.env.REACT_APP_API_CORE}safesend/dailystats`,
    data: {
      idCompany,
      startDate,
      endDate,
    },
  }).then((result: any = {}) => {
    return result.data.data || result;
  });
};
