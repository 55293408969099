import React, { useCallback, useMemo } from 'react';

import { IFeatureCard } from 'pages/Features/types';
import { Icon, Text } from '@mindbaz/ui-v2';
import { useTranslation } from '@mindbaz/app-manager';
import {
  BafflingProblem,
  ContainerCTA,
  ContainerCard,
  CustomText,
  Flex,
  Goal,
  Label,
  LearnMore,
  StyledButton,
} from 'pages/Features/styledComponents';

const FEATURES = {
  SAFESEND: 'safesend',
  DASHBOARDS: 'dashboards',
  SNAPSHOT: 'snapshot',
};

const featuresName = [
  FEATURES.SAFESEND,
  FEATURES.DASHBOARDS,
  FEATURES.SNAPSHOT,
];

const FeatureCard = (props: IFeatureCard) => {
  const { translate } = useTranslation();

  const {
    label,
    title,
    description,
    bafflingProblem,
    goal,
    activeFeatureCTA,
    hasUserAccessFeature,
    isNewFeature,
    illustration,
    handleFeatureMoreClick,
    activeMore,
    temporaryFreeSubscription,
  } = props;

  const cardTheme = useMemo(() => {
    return isNewFeature ? 'dark' : 'default';
  }, [isNewFeature]);

  const isColorTextSecondary = useMemo(() => {
    return cardTheme === 'dark' ? 'secondary' : 'primary';
  }, [cardTheme]);

  const handleClick = () => {
    handleFeatureMoreClick(label);
  };

  const handleAccessRedirection = useCallback(() => {
    const featureWillRedirect = featuresName.find((type: string) => {
      return type === label;
    });
    window.location.replace(
      `${process.env.REACT_APP_ADMIN_URL}${featureWillRedirect}`
    );
  }, [label]);

  const handleNoAccessRedirection = useCallback(() => {
    const featureWillRedirect = featuresName.find((type: string) => {
      return type === label;
    });
    if (featureWillRedirect === FEATURES.SAFESEND)
      window.location.replace(
        `${process.env.REACT_APP_MINDBAZ_URL}${featureWillRedirect}`
      );
    else if (featureWillRedirect === FEATURES.SNAPSHOT)
      window.location.replace(
        `${process.env.REACT_APP_ADMIN_URL}offer/${featureWillRedirect}`
      );
    else
      window.location.replace(
        `${process.env.REACT_APP_ADMIN_URL}offers/${featureWillRedirect}`
      );
  }, [label]);

  return (
    <ContainerCard className={cardTheme}>
      <Flex className="justify-between">
        <div>
          <Label className={cardTheme}>
            <Text color={isColorTextSecondary}>{label.toUpperCase()}</Text>
          </Label>
          <CustomText className="title-card">
            <Text color={isColorTextSecondary} variant="h1">
              {title}
            </Text>
          </CustomText>
          <CustomText className={`my-16 subtitle-card-${cardTheme}`}>
            <Text variant="subtitle">{description}</Text>
          </CustomText>
          <ContainerCTA>
            {/* eslint-disable-next-line no-nested-ternary */}
            {(label === 'snapshot' && temporaryFreeSubscription.dailyReport) ||
            (label === 'dashboards' && temporaryFreeSubscription.dashboards) ? (
              <Text variant="h4" color="pink">
                {translate('features.cards.freeAccess')}
              </Text>
            ) : hasUserAccessFeature ? (
              <StyledButton onClick={handleAccessRedirection} variant="rubixo">
                {translate('features.manageOffer')}
              </StyledButton>
            ) : (
              <StyledButton
                onClick={handleNoAccessRedirection}
                variant="rubixo"
              >
                {activeFeatureCTA}
              </StyledButton>
            )}

            <Flex
              className="justify-center align-center pointer"
              onClick={handleClick}
            >
              <LearnMore color={isColorTextSecondary}>
                {translate('features.cards.learnMore')}
              </LearnMore>
              <Icon>
                {activeMore === label ? 'expand_less' : 'expand_more'}
              </Icon>
            </Flex>
          </ContainerCTA>
        </div>
        <div>
          <img src={illustration} alt="" />
        </div>
      </Flex>

      {activeMore === label && (
        <Flex className="mt-40 gap-24 justify-center">
          <BafflingProblem className={cardTheme}>
            <Text color={isColorTextSecondary}>
              {translate('features.cards.bafflingProblem')}
            </Text>
            <Text color="lighter">{bafflingProblem}</Text>
          </BafflingProblem>
          <Goal>
            <Text color={isColorTextSecondary}>
              {translate('features.cards.goal')}
            </Text>
            <Text color="lighter">{goal}</Text>
          </Goal>
        </Flex>
      )}
    </ContainerCard>
  );
};

export default FeatureCard;
