import { fetch } from '@mindbaz/app-manager';

const typesAlert = [
  {
    name: 'SNPRI_',
    sort: 1,
  },
  {
    name: 'BL_domain',
    sort: 2,
  },
  {
    name: 'domains',
    sort: 3,
  },
];

export const getPerfByDB = async (date: string | null) => {
  return fetch({
    method: 'GET',
    url: `${process.env.REACT_APP_API_CORE}DailyReport/perfbydb`,
    params: { date },
  })
    .then((result: any) => {
      return result.data.data.sort((a: any, b: any) => {
        if (a.siteName < b.siteName) {
          return -1;
        }
        if (a.siteName > b.siteName) {
          return 1;
        }
        return 0;
      });
    })
    .catch((error) => {
      return error;
    });
};

export const getPerfByISP = async (
  idSite: number | null,
  date: string | null
) => {
  return fetch({
    method: 'GET',
    url: `${process.env.REACT_APP_API_CORE}DailyReport/${idSite}/perfbyisp`,
    params: { date },
  })
    .then((result: any) => {
      return result.data.data.sort((a: any, b: any) => {
        if (a.siteName < b.siteName) {
          return -1;
        }
        if (a.siteName > b.siteName) {
          return 1;
        }
        return 0;
      });
    })
    .catch((error) => {
      return error;
    });
};

export const getDBAlerts = async (
  idSite: number | null,
  date: string | null
) => {
  return fetch({
    method: 'GET',
    url: `${process.env.REACT_APP_API_CORE}DailyReport/${idSite}/dbalerts`,
    params: { date },
  })
    .then((result: any) => {
      return result.data.data.sort((a: any, b: any) => {
        const firstType = typesAlert.find((type) =>
          a.type.startsWith(type.name)
        );
        const secondType = typesAlert.find((type) =>
          b.type.startsWith(type.name)
        );
        if (firstType && secondType && firstType.sort < secondType.sort) {
          return -1;
        }
        if (firstType && secondType && firstType.sort > secondType.sort) {
          return 1;
        }
        return 0;
      });
    })
    .catch((error) => {
      return error;
    });
};

export const getCompanyAlerts = async (date: string | null) => {
  return fetch({
    method: 'GET',
    url: `${process.env.REACT_APP_API_CORE}DailyReport/companyalerts`,
    params: { date },
  })
    .then((result: any) => {
      return result.data.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getComplaintsByISP = async (
  idSite: number | null,
  date: string | null
) => {
  return fetch({
    method: 'GET',
    url: `${process.env.REACT_APP_API_CORE}DailyReport/${idSite}/complaintsbyisp`,
    params: { date },
  })
    .then((result: any) => {
      return result.data.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getPerson = async () => {
  return fetch({
    method: 'GET',
    url: `${process.env.REACT_APP_API_RIGHTS}person `,
  }).then((result: any = {}) => {
    return result.data.data || result;
  });
};

export const getDatabases = async (idCompany: number) => {
  return fetch({
    url: `${process.env.REACT_APP_API_RIGHTS}Company/${idCompany}/sites`,
    method: 'GET',
  }).then((result: any = {}) => {
    return (
      result.data.data.sort((a: any, b: any) => {
        if (a.siteName < b.siteName) {
          return -1;
        }
        if (a.siteName > b.siteName) {
          return 1;
        }
        return 0;
      }) || result
    );
  });
};
