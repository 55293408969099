import { fetch } from '@mindbaz/app-manager';

export const isAdmin = async () => {
  return fetch({
    url: `${process.env.REACT_APP_API_CORE}features/canAdmin`,
    method: 'GET',
  }).then((result: any = {}) => {
    return result.data.data || result;
  });
};

const mapUserInformation = (data: any) => {
  const {
    id,
    civility,
    firstName: firstname,
    lastName: lastname,
    login: email,
    companyName: company,
    group,
    adminList,
  } = data;
  return {
    id,
    civility,
    firstname,
    lastname,
    email,
    company,
    groupId: parseInt(Object.keys(group)[0], 10),
    role: group[Object.keys(group)[0]],
    adminList,
  };
};

export const getUserInformation = async () => {
  return fetch({
    method: 'GET',
    url: `${process.env.REACT_APP_API_RIGHTS}useraccount/informations`,
  }).then((result: any = {}) => {
    if (result.status !== 200) {
      throw new Error(result.status.statusText);
    }
    if (result.data.data) {
      return mapUserInformation(result.data.data);
    }
    return result;
  });
};

export const getPerson = async () => {
  return fetch({
    method: 'GET',
    url: `${process.env.REACT_APP_API_RIGHTS}person `,
  }).then((result: any = {}) => {
    return result.data.data || result;
  });
};
