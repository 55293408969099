import { Button } from '@mindbaz/ui-v2';
import { styled } from '@mui/material';

export const Flex = styled('div')({
  display: 'flex',
  '&.justify-between': {
    justifyContent: 'space-between',
  },
  '&.justify-end': {
    justifyContent: 'end',
    alignItems: 'center',
  },
  '&.justify-align-center': {
    justifyContent: 'center',
    alignItems: 'center',
    height: 200,
  },
  '&.align-center': {
    alignItems: 'center',
  },
  '&.pb-4': {
    paddingBottom: '4rem',
  },
  '&.mb-5': {
    marginBottom: 5,
  },
  '&.mb-10': {
    marginBottom: 10,
  },
  '&.mb-20': {
    marginBottom: 20,
  },
  '&.mt-50': {
    marginTop: 50,
  },
  '&.flex-column': {
    flexDirection: 'column',
  },
});

export const StyledCards = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(2,minmax(250px,1fr))',
  columnGap: '2.1rem',
  marginTop: '1rem',
  '& > div': {
    width: 250,
  },
});

export const StyledButton = styled(Button)({
  alignSelf: 'center',
});

export const StyledRadioButton = styled('input')({
  margin: 10,
  '&:checked': {
    accentColor: '#232323',
  },
});

export const ChartContainer = styled('div')({
  margin: '10px 0',
  padding: 10,
  borderRadius: '12px',
  backgroundColor: 'white',
});

export const PopupContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  '& > button': {
    alignSelf: 'end',
  },
});

export const Link = styled('a')({
  color: '#0298D6',
});
