import React from 'react';
import { Icon } from '@mindbaz/ui-v2';

import ICard from 'packages/types/Cards/ICard';
import {
  StyledCard,
  StyledCardTitleWrapper,
  StyledCardTitle,
  StyledTextContent,
} from './styledComponents';

const Card = ({ titleIcon, titleText, Content }: ICard) => {
  return (
    <StyledCard
      isAnimated
      isClickable
      title={
        <StyledCardTitleWrapper>
          <Icon size={50} color="secondary">
            {titleIcon}
          </Icon>

          <StyledCardTitle variant="h3">{titleText}</StyledCardTitle>
        </StyledCardTitleWrapper>
      }
    >
      <StyledTextContent align="center">{Content}</StyledTextContent>
    </StyledCard>
  );
};

export default Card;
