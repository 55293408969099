import { styled } from '@mui/material';

const StyledNav = styled('nav')({
  display: 'grid',
  gridTemplateColumns: 'repeat(260,minmax(260px,1fr))',
  columnGap: '2.1rem',
  marginTop: '1rem',
});

export default StyledNav;
