import React, { useCallback } from 'react';
import { useTranslation } from '@mindbaz/app-manager';
import { Text, Button } from '@mindbaz/ui-v2';

import {
  StyledContainer,
  StyledNotListedLocationOutlinedIcon,
} from './styledComponents';

const NoFound = () => {
  const { translate } = useTranslation();

  const handleClick = useCallback(
    () => window.location.replace(process.env.REACT_APP_MINDBAZ_MAIL_URL || ''),
    []
  );

  return (
    <StyledContainer>
      <StyledNotListedLocationOutlinedIcon />
      <Text variant="h1" align="center">
        {translate('404.title')}
      </Text>
      <br />
      <Text align="center">{translate('404.description')}</Text>
      <br />
      <Button onClick={handleClick}>{translate('404.button')}</Button>
    </StyledContainer>
  );
};

export default NoFound;
