import React, { useMemo, useState } from 'react';
import { Text } from '@mindbaz/ui-v2';
import { useTranslation } from '@mindbaz/app-manager';

import { useQuery } from '@tanstack/react-query';
import {
  getDailyReportAccess,
  getDashboardsAccess,
  getSafeSendAccess,
} from 'packages/endpoints/access';
import { isAdmin } from 'packages/endpoints/common';

import safesendIllusatration from 'assets/features/safesend_illustration.svg';
import dashboardsIllusatration from 'assets/features/dashboards_illustration.svg';
import snapshotIllusatration from 'assets/features/snapshot_illustration.svg';

import { Header, NewFeatureBanner, Footer, FeatureCard } from './Blocks/index';

import { TFeature } from './types';

import {
  ContainerFeatures,
  CustomText,
  PageFeatures,
} from './styledComponents';

const FEATURES = {
  SAFESEND: 'safesend',
  DASHBOARD: 'dashboards',
  SNAPSHOT: 'snapshot',
};

const NEW_FEATURE = FEATURES.SAFESEND;

const Features = () => {
  const { translate } = useTranslation();

  const [activeMore, setActiveMore] = useState('');

  const { data: dailyReportAccess } = useQuery(['dailyreport-access'], () =>
    getDailyReportAccess()
  );

  const { data: dashboardsAccess } = useQuery(['dashboards-access'], () =>
    getDashboardsAccess()
  );

  const { data: isAdminUser } = useQuery(['is-admin'], () => isAdmin());

  const { data: safeSendAccess } = useQuery(
    ['safesend-access'],
    () => getSafeSendAccess(),
    {
      suspense: false,
    }
  );

  /**
   * true if there is temporary free access for the user.
   */
  const temporaryFreeSubscription = useMemo(() => {
    return {
      dashboards: dashboardsAccess.tempFreeAccess,
      dailyReport: dailyReportAccess.tempFreeAccess,
    };
  }, [dailyReportAccess, dashboardsAccess]);

  const titleHeader = [
    FEATURES.SAFESEND,
    FEATURES.DASHBOARD,
    FEATURES.SNAPSHOT,
  ];

  const FEATURES_CARD: TFeature[] = useMemo(() => {
    const FEATURES_CONFIG = [
      {
        translateKey: FEATURES.SAFESEND,
        illustration: safesendIllusatration,
        hasUserAccessFeature: safeSendAccess && isAdminUser === true,
      },
      {
        translateKey: FEATURES.DASHBOARD,
        illustration: dashboardsIllusatration,
        hasUserAccessFeature:
          dashboardsAccess.userHasAccess && dashboardsAccess.isSuperAdmin,
      },
      {
        translateKey: FEATURES.SNAPSHOT,
        illustration: snapshotIllusatration,
        hasUserAccessFeature:
          dailyReportAccess.userHasAccess && dailyReportAccess.isSuperAdmin,
      },
    ];

    return FEATURES_CONFIG.map(
      ({ translateKey, illustration, hasUserAccessFeature }) => ({
        label: translateKey,
        title: translate(`features.cards.${translateKey}.title`),
        description: translate(`features.cards.${translateKey}.description`),
        bafflingProblem: translate(
          `features.cards.${translateKey}.bafflingProblem`
        ),
        goal: translate(`features.cards.${translateKey}.goal`),
        activeFeatureCTA: translate(
          `features.cards.${translateKey}.activateFeature`
        ),
        hasUserAccessFeature,
        illustration,
      })
    );
  }, [
    dailyReportAccess.isSuperAdmin,
    dailyReportAccess.userHasAccess,
    dashboardsAccess.isSuperAdmin,
    dashboardsAccess.userHasAccess,
    isAdminUser,
    safeSendAccess,
    translate,
  ]);

  const isNewFeature = (feature: string) => {
    return NEW_FEATURE === feature;
  };

  const handleClick = (label: string) => {
    setActiveMore(label);
    if (label === activeMore) setActiveMore('');
  };

  const generateFeatures = () => {
    return FEATURES_CARD.map((feature: TFeature) => (
      <div id={feature.label}>
        <FeatureCard
          label={feature.label}
          title={feature.title}
          description={feature.description}
          bafflingProblem={feature.bafflingProblem}
          goal={feature.goal}
          activeFeatureCTA={feature.activeFeatureCTA}
          hasUserAccessFeature={feature.hasUserAccessFeature}
          isNewFeature={isNewFeature(feature.label)}
          illustration={feature.illustration}
          handleFeatureMoreClick={handleClick}
          activeMore={activeMore}
          temporaryFreeSubscription={temporaryFreeSubscription}
        />
      </div>
    ));
  };

  return (
    <PageFeatures>
      <Header navigations={titleHeader} />
      <NewFeatureBanner newFeature={NEW_FEATURE} />
      <ContainerFeatures>
        <CustomText className="mb-16 mt-48 title">
          <Text variant="h1" align="center">
            {translate('features.title')}
          </Text>
        </CustomText>
        <CustomText className="mb-48 subtitle">
          <Text variant="subtitle" align="center">
            {translate('features.description')}
          </Text>
        </CustomText>
        {generateFeatures()}
        <Footer />
      </ContainerFeatures>
    </PageFeatures>
  );
};

export default Features;
