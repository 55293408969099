import React from 'react';
import useAppManager, { AppCode } from '@mindbaz/app-manager';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as Sentry from '@sentry/react';

import { Loader } from '@mindbaz/ui-v2';
import PublicRoutes, { publicPages } from './pages/PublicRoutes';
import PrivateRoutes from './pages/PrivateRoutes';
import EN from './packages/locales/en.json';

const { REACT_APP_API_ENV, REACT_APP_APPLICATION_ID }: any = process.env;

if (REACT_APP_API_ENV !== 'production') document.title += ' PREPROD';

const translationFiles = {
  EN,
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
});

const App = () => {
  const isPublicPage = publicPages.includes(window.location.pathname);

  const [status] = useAppManager({
    appId: REACT_APP_APPLICATION_ID,
    translationFiles,
    appCode: AppCode.MY,
    env: process.env.REACT_APP_API_ENV === 'production' ? 'prod' : 'preprod',
    disableTokenProvider: isPublicPage,
  });

  if (status !== 'success' && status !== 'error') return <Loader />;
  if (status === 'error') {
    // TODO: redirect to 403
  }
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      {status === 'success' && isPublicPage && <PublicRoutes />}
      {status === 'success' && !isPublicPage && <PrivateRoutes />}
    </QueryClientProvider>
  );
};

export default Sentry.withProfiler(App);
