import React, { ReactNode } from 'react';

import Header from '../header';
import StyledMain from './styledComponents';

const layout = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <Header />
      <StyledMain>{children}</StyledMain>
    </>
  );
};

export default layout;
