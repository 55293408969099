import { styled } from '@mui/material';

export const StyledHeader = styled('header')({
  backgroundColor: 'white',
  height: '4.5rem',
  display: 'flex',
  alignItems: 'center',
  padding: '0 1.25rem 0 1.25rem',
});

export const StyledImg = styled('img')({
  width: '100%',
  paddingTop: '0.4rem',
  paddingLeft: '0.3rem',
});

export const StyledWrapperImg = styled('div')({
  width: '100%',
  maxWidth: '12rem',
});

export const StyledWrapperNav = styled('div')({
  marginLeft: 'auto',
  '& > svg:not(:last-child)': {
    paddingRight: '1rem',
  },
});
