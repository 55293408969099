import { Card, Text, Button, colors } from '@mindbaz/ui-v2';
import { styled } from '@mui/material';

export const StyledCard = styled(Card)({
  width: '100%',
  maxWidth: '15rem',
  textAlign: 'center',
});

export const StyledCardTitleWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const StyledCardTitle = styled(Text)({
  marginTop: '1.5rem',
});

export const StyledTextContent = styled(Text)({
  paddingTop: '1rem',
  height: '5rem',
});

export const CardContent = styled('div')({
  textAlign: 'center',
});

export const StyledButton = styled(Button)({
  marginTop: '1rem',
});

export const StyledGoldButton = styled(Button)({
  marginTop: '1rem',
  backgroundColor: `${colors.Gold} !important`,
  borderColor: `${colors.Gold} !important`,
});
