import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns';

export const PERIOD_PANEL = {
  THIRTY_LAST_DAYS: 'thirtyLastDays',
  CURRENT_MONTH: 'currentMonth',
  LAST_MONTH: 'lastMonth',
  THREE_LAST_MONTHS: 'threeLastMonths',
  SIX_LAST_MONTHS: 'sixLastMonths',
};

const currentDate = new Date();
const previousMonth = subMonths(currentDate, 1);
const threeMonthAgo = subMonths(currentDate, 3);
const sixMonthAgo = subMonths(currentDate, 6);

const formatDateStandard = (date: Date) => format(date, 'dd/MM/yyyy');

const previousMonthDate = formatDateStandard(previousMonth);
const currentNowDate = formatDateStandard(currentDate);
const startOfCurrentMonth = formatDateStandard(startOfMonth(currentDate));
const startOfPreviousMonth = formatDateStandard(startOfMonth(previousMonth));
const startOfThreeMonthAgo = formatDateStandard(startOfMonth(threeMonthAgo));
const startOfSixMonthAgo = formatDateStandard(startOfMonth(sixMonthAgo));
const endOfPreviousMonth = formatDateStandard(endOfMonth(previousMonth));

export const periodOptions = {
  [PERIOD_PANEL.THIRTY_LAST_DAYS]: {
    startDate: previousMonthDate,
    endDate: currentNowDate,
  },
  [PERIOD_PANEL.CURRENT_MONTH]: {
    startDate: startOfCurrentMonth,
    endDate: currentNowDate,
  },
  [PERIOD_PANEL.LAST_MONTH]: {
    startDate: startOfPreviousMonth,
    endDate: endOfPreviousMonth,
  },
  [PERIOD_PANEL.THREE_LAST_MONTHS]: {
    startDate: startOfThreeMonthAgo,
    endDate: endOfPreviousMonth,
  },
  [PERIOD_PANEL.SIX_LAST_MONTHS]: {
    startDate: startOfSixMonthAgo,
    endDate: endOfPreviousMonth,
  },
};
