import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from '@mindbaz/app-manager';
import { useNavigate } from 'react-router-dom';
import { DataGrid, Icon } from '@mindbaz/ui-v2';
import { uniqueId } from 'lodash';
import { format } from 'date-fns';

import {
  getCompanyAlerts,
  getComplaintsByISP,
  getDBAlerts,
  getPerfByDB,
  getPerfByISP,
} from 'packages/endpoints/dailyReport';
import {
  TCard,
  TColorsLevel,
  TDateAutocomplete,
  TGrid,
  TAlert,
  TPerfByISP,
} from './types';
import useDataGrid from './useDataGrid';
import Chart from '../../components/common/Chart';
import { Container, Flex, TextCustom } from './styledComponents';

const useCards = (
  dateUse: TDateAutocomplete,
  selectedDatabase: any,
  appleMPPChecked: boolean
) => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const [isGoneError, setGoneError] = useState(false);
  const { columns } = useDataGrid();

  const typeAlert = useMemo(
    () => ({
      preblock: 'SNPRI_',
      blacklist: 'BL_domain',
      blacklistIp: 'BL_ip',
      domains: 'Delegation',
    }),
    []
  );

  const levelPreblock = useMemo(
    () => ({
      SNPRI_LVL1: 1,
      SNPRI_LVL2: 2,
      SNPRI_LVL3: 3,
      SNPRI_LVL4: 4,
    }),
    []
  );

  const isIPAdress = (input: string) => {
    const regex =
      /\b(?:(?:2(?:[0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9])\.){3}(?:(?:2([0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9]))\b\.15/i;
    return regex.test(input);
  };

  const { date } = dateUse;

  const { data: perfByDB, refetch: refetchPerfByDB } = useQuery(
    ['getPerfByDB'],
    () => getPerfByDB(date),
    {
      onSuccess: (result) => {
        if (result.response?.status === 410) {
          setGoneError(true);
        }
      },
    }
  );

  const { data: companyAlerts, refetch: refetchCompanyAlert } = useQuery(
    ['getCompanyAlerts'],
    () => getCompanyAlerts(date),
    {
      onSuccess: (result) => {
        if (result.response?.status === 410) {
          setGoneError(true);
        }
      },
    }
  );

  const {
    data: deliverabilityAlertData,
    refetch: refetchDeliverabilityAlertData,
  } = useQuery(['getDBAlerts'], () => getDBAlerts(selectedDatabase.id, date), {
    onSuccess: (result) => {
      if (result.response?.status === 410) {
        setGoneError(true);
      }
    },
  });

  const { data: complaints, refetch: refetchComplaints } = useQuery(
    ['getComplaintsByISP'],
    () => getComplaintsByISP(selectedDatabase.id, date),
    {
      onSuccess: (result) => {
        if (result.response?.status === 410) {
          setGoneError(true);
        }
      },
    }
  );

  const { data: perfByISP, refetch: refetchPerfByISP } = useQuery(
    ['getPerfByISP'],
    () => getPerfByISP(selectedDatabase.id, date),
    {
      onSuccess: (result) => {
        if (result.response?.status === 410) {
          setGoneError(true);
        }
      },
    }
  );

  if (isGoneError) {
    navigate('/gone');
  }

  useEffect(() => {
    refetchPerfByDB();
    refetchCompanyAlert();
    refetchComplaints();
    refetchDeliverabilityAlertData();
    refetchPerfByISP();
  }, [
    date,
    selectedDatabase.id,
    refetchPerfByDB,
    refetchCompanyAlert,
    refetchComplaints,
    refetchDeliverabilityAlertData,
    refetchPerfByISP,
  ]);

  const perfByDBWithId = useMemo(() => {
    return (
      perfByDB.length > 0 &&
      perfByDB.map((perf: TGrid) => {
        return {
          ...perf,
          id: uniqueId(),
          yLabel: perf.siteName,
          openersPct: appleMPPChecked ? perf.openersPct : perf.humanOpenersPct,
        };
      })
    );
  }, [perfByDB, appleMPPChecked]);

  const perfByISPWithId = useMemo(() => {
    return (
      perfByISP.length > 0 &&
      perfByISP.map((perf: TGrid) => {
        return {
          ...perf,
          id: uniqueId(),
          yLabel: perf.faiName,
          openersPct: appleMPPChecked ? perf.openersPct : perf.humanOpenersPct,
          siteName: '',
        };
      })
    );
  }, [perfByISP, appleMPPChecked]);

  const preblockAlert: TAlert[] = useMemo(
    () =>
      companyAlerts.length > 0 &&
      companyAlerts
        .filter(
          (alert: TAlert) =>
            alert && alert.type && alert.type.startsWith(typeAlert.preblock)
        )
        .sort(
          (a: any, b: any) =>
            levelPreblock[b.type as keyof typeof levelPreblock] -
            levelPreblock[a.type as keyof typeof levelPreblock]
        ),
    [companyAlerts, typeAlert.preblock, levelPreblock]
  );

  const blacklistData: TAlert[] = useMemo(
    () =>
      companyAlerts.length > 0 &&
      companyAlerts.filter(
        (alert: TAlert) =>
          alert.type === typeAlert.blacklist ||
          alert.type === typeAlert.blacklistIp
      ),
    [companyAlerts, typeAlert]
  );

  const domainsData: TAlert[] = useMemo(
    () =>
      companyAlerts.length > 0 &&
      companyAlerts.filter((alert: TAlert) => alert.type === typeAlert.domains),
    [companyAlerts, typeAlert.domains]
  );

  const nbPreblock = preblockAlert.length;
  const nbBlacklist = blacklistData.length;
  const nbUndelegated = domainsData.length;
  const nbDeliverabilityAlert = deliverabilityAlertData.length;

  const emailsSentByISP = useMemo(
    () =>
      perfByISPWithId.length > 0 &&
      perfByISPWithId
        .map((perf: TPerfByISP) => {
          return {
            volumeSent: perf.volumeSent,
            WithoutText: perf.volumeSent,
            label: perf.faiName,
            previousData: perf.previousVolumeSent,
          };
        })
        .sort((a: TPerfByISP, b: TPerfByISP) => b.volumeSent - a.volumeSent),
    [perfByISPWithId]
  );

  const dataChartSpam = useMemo(
    () => [
      {
        Orange: complaints[0]?.orangeComplaints,
        LaPoste: complaints[0]?.laposteComplaints,
        SFR: complaints[0]?.sfrComplaints,
        Hotmail: complaints[0]?.hotmailComplaints,
        Yahoo: complaints[0]?.yahooComplaints,
        label: 'Spams Volume',
      },
    ],
    [complaints]
  );

  const dataChartSpamRate = useMemo(
    () => [
      {
        Orange: complaints[0]?.orangeComplaintsRate,
        LaPoste: complaints[0]?.laposteComplaintsRate,
        SFR: complaints[0]?.sfrComplaintsRate,
        Hotmail: complaints[0]?.hotmailComplaintsRate,
        Yahoo: complaints[0]?.yahooComplaintsRate,
        label: 'Spam Rate (in %)',
      },
    ],
    [complaints]
  );

  const colorsChart = useMemo(() => ['#2b3a67'], []);
  const colorsChartSpam = useMemo(
    () => ['#D1603D', '#ECC30B', '#BB4430', '#0298D6', '#40028F', '#000000'],
    []
  );

  const dataStacked = useMemo(
    () =>
      perfByISPWithId.length > 0 &&
      perfByISPWithId.map((perf: any) => {
        return {
          unsent: perf.nbAddressUnSent,
          undelivered: perf.nbAddressUnDelivered,
          delivered: perf.nbAddressDelivered,
          label: perf.faiName,
        };
      }),
    [perfByISPWithId]
  );

  const colorsStacked = useMemo(() => ['#bb4430', '#e0a458', '#2B3A67'], []);

  const noResult = useCallback(
    (translation: string, dateOption?: string) => {
      return (
        <Flex className="justify-align-center">
          <Icon color="success">check_circle</Icon>
          <TextCustom className="ml-10" variant="subtitle">
            {translate(translation, { date: dateOption })}
          </TextCustom>
        </Flex>
      );
    },
    [translate]
  );

  const GENERAL_BLOCK = 'dailyReport.tabs.general.block';
  const PERFORMANCE_BLOCK = 'dailyReport.tabs.performance.block';

  const colorsLevel: TColorsLevel = useMemo(
    () => ({
      1: 'primary',
      2: 'warning',
      3: 'warning',
      4: 'error',
    }),
    []
  );

  const preblockContent = useCallback(
    (props: TAlert, summary?: boolean) => {
      const flexDirection = summary ? 'flex-column' : 'mb-10 ';
      return (
        <Flex className={`justify-center ${flexDirection}`} key={uniqueId()}>
          {summary ? (
            <TextCustom className="ml-5" variant="h3">
              {translate(`${GENERAL_BLOCK}.preblockSummary`)} {props.name} (
              {translate(`${GENERAL_BLOCK}.preblock.level`)}
              {levelPreblock[props.type as keyof typeof levelPreblock]})
            </TextCustom>
          ) : (
            <>
              <TextCustom className="ml-5" variant="h3">
                {props.name}
              </TextCustom>
              <TextCustom
                className="ml-5"
                variant="h3"
                color={
                  colorsLevel[
                    levelPreblock[props.type as keyof typeof levelPreblock]
                  ]
                }
              >
                ({translate(`${GENERAL_BLOCK}.preblock.level`)}
                {levelPreblock[props.type as keyof typeof levelPreblock]})
              </TextCustom>
            </>
          )}
          <TextCustom className="ml-5" variant="body">
            {props.details}
          </TextCustom>
        </Flex>
      );
    },
    [translate, colorsLevel, levelPreblock]
  );

  const blackListContent = useCallback(
    (props: TAlert, summary?: boolean) => {
      const mb10 = summary ? '' : 'mb-10';
      return (
        <Container className={mb10} key={uniqueId()}>
          <TextCustom className="ml-5" variant="h3">
            {summary && translate(`${GENERAL_BLOCK}.blacklistSummary`)}
            {isIPAdress(props.name) ? `IP ${props.name}` : props.name}
          </TextCustom>
          <TextCustom className="ml-5" variant="body">
            {props.details}
          </TextCustom>
        </Container>
      );
    },

    [translate]
  );

  const domainsContent = useCallback(
    (props: TAlert, summary?: boolean) => {
      const mb10 = summary ? '' : 'mb-10';
      return (
        <Container className={mb10}>
          {summary && (
            <TextCustom className="ml-5" variant="h3">
              {translate(`${GENERAL_BLOCK}.undelegatedSummary`)}
            </TextCustom>
          )}
          <TextCustom className="ml-5" variant="body">
            {props.name}
          </TextCustom>
        </Container>
      );
    },
    [translate]
  );

  const deliverabilityAlertContent = useCallback(() => {
    return (
      deliverabilityAlertData.length > 0 &&
      deliverabilityAlertData.map((props: TAlert) => {
        if (props.type.startsWith(typeAlert.preblock))
          return (
            <Flex className="align-center mb-5">
              <Icon>warning_amberIcon</Icon>
              {preblockContent(props, true)}
            </Flex>
          );
        if (
          props.type === typeAlert.blacklist ||
          props.type === typeAlert.blacklistIp
        )
          return (
            <Flex className="align-center mb-5">
              <Icon>block</Icon>
              {blackListContent(props, true)}
            </Flex>
          );
        if (props.type === typeAlert.domains)
          return (
            <Flex className="align-center mb-5">
              <Icon>dns_outlined</Icon> {domainsContent(props, true)}
            </Flex>
          );
        return '';
      })
    );
  }, [
    typeAlert,
    deliverabilityAlertData,
    preblockContent,
    blackListContent,
    domainsContent,
  ]);

  const generateContent = useCallback(
    (data: any, content: (props: any) => void) => {
      return data.length > 0 && data.map((props: any) => content(props));
    },
    []
  );

  const getContent = useCallback(
    (noResultTranslation: string, content: any) => {
      return content.length > 0 ? content : noResult(noResultTranslation);
    },
    [noResult]
  );

  const generalCards: TCard[][] = useMemo(
    () => [
      [
        {
          label: translate(`${GENERAL_BLOCK}.preblock.title`, {
            nbPreblock,
          }),
          icon: (
            <Icon color={nbPreblock ? 'error' : undefined}>
              warning_amberIcon
            </Icon>
          ),
          content: getContent(
            `${GENERAL_BLOCK}.noPreblock`,
            generateContent(preblockAlert, preblockContent)
          ),
          isScrollable: true,
        },
      ],
      [
        {
          label: translate(`${GENERAL_BLOCK}.blacklist`, {
            nbBlacklist,
          }),
          icon: <Icon color={nbBlacklist ? 'error' : undefined}>block</Icon>,
          content: getContent(
            `${GENERAL_BLOCK}.noBlacklist`,
            generateContent(blacklistData, blackListContent)
          ),
          isScrollable: true,
        },
      ],
      [
        {
          label: translate(`${GENERAL_BLOCK}.undelegated`, {
            nbUndelegated,
          }),
          icon: (
            <Icon color={nbUndelegated ? 'error' : undefined}>
              dns_outlined
            </Icon>
          ),
          content: getContent(
            `${GENERAL_BLOCK}.noUndelegated`,
            generateContent(domainsData, domainsContent)
          ),
          isScrollable: true,
        },
      ],
      [
        {
          label: translate(`${GENERAL_BLOCK}.performance`, {
            nbUndelegated,
          }),
          icon: <Icon>insert_chart_outlined</Icon>,
          content: (
            <DataGrid
              maximumRowActionsDisplayed={3}
              checkboxSelection={false}
              columns={columns}
              rows={perfByDBWithId}
              disableSearchBar
              disableFilters
            />
          ),
        },
      ],
    ],
    [
      translate,
      perfByDBWithId,
      columns,
      nbPreblock,
      nbBlacklist,
      nbUndelegated,
      getContent,
      preblockContent,
      blackListContent,
      domainsContent,
      generateContent,
      preblockAlert,
      blacklistData,
      domainsData,
    ]
  );

  const noSpams =
    dataChartSpam[0].Orange === 0 &&
    dataChartSpam[0].LaPoste === 0 &&
    dataChartSpam[0].Hotmail === 0 &&
    dataChartSpam[0].SFR === 0 &&
    dataChartSpam[0].Yahoo === 0;

  const performancesCards: TCard[][] = useMemo(
    () => [
      [
        {
          label: translate(`${PERFORMANCE_BLOCK}.email`),
          icon: <Icon>mark_email_read</Icon>,
          content: emailsSentByISP && (
            <Chart
              margin={{
                bottom: 10,
                left: 80,
                right: 120,
                top: 5,
              }}
              layout="horizontal"
              data={emailsSentByISP || []}
              enableGridY={false}
              colors={colorsChart}
              isGrouped
              withLegend={false}
            />
          ),
        },
      ],
      [
        {
          label: translate(`${PERFORMANCE_BLOCK}.deliverability`),
          icon: <Icon>mail_outline</Icon>,
          content: dataStacked && dataStacked.length > 0 && (
            <Chart
              margin={{
                bottom: 10,
                left: 80,
                right: 80,
                top: 5,
              }}
              layout="horizontal"
              data={dataStacked}
              colors={colorsStacked}
            />
          ),
        },
      ],
      [
        {
          label: translate(`${PERFORMANCE_BLOCK}.deliverabilityAlert`, {
            nbDeliverabilityAlert,
          }),
          icon: (
            <Icon color={nbDeliverabilityAlert ? 'error' : undefined}>
              warning_amberIcon
            </Icon>
          ),
          content: getContent(
            `${PERFORMANCE_BLOCK}.noDeliverabilityAlert`,
            deliverabilityAlertContent()
          ),
          isScrollable: true,
        },
      ],
      [
        {
          label: translate(`${PERFORMANCE_BLOCK}.performance`),
          icon: <Icon>insert_chart_outlined</Icon>,
          content: (
            <DataGrid
              maximumRowActionsDisplayed={3}
              checkboxSelection={false}
              columns={columns}
              rows={perfByISPWithId}
              disableSearchBar
              disableFilters
            />
          ),
        },
      ],
      [
        {
          label: translate(`${PERFORMANCE_BLOCK}.spamVol`),
          content: noSpams ? (
            noResult(
              `${PERFORMANCE_BLOCK}.noResult`,
              dateUse.date
                ? format(new Date(dateUse?.date), 'dd/MM/yyyy')
                : undefined
            )
          ) : (
            <Chart
              margin={{
                bottom: 10,
                left: 40,
                right: 110,
                top: 5,
              }}
              layout="vertical"
              data={dataChartSpam}
              colors={colorsChartSpam}
              groupedWithTooltip
              enableGridY
              isGrouped
              innerPadding={5}
              markers
            />
          ),
        },
        {
          label: translate(`${PERFORMANCE_BLOCK}.spamPercent`),
          content: noSpams ? (
            noResult(
              `${PERFORMANCE_BLOCK}.noResult`,
              dateUse.date
                ? format(new Date(dateUse?.date), 'dd/MM/yyyy')
                : undefined
            )
          ) : (
            <Chart
              margin={{
                bottom: 10,
                left: 40,
                right: 110,
                top: 5,
              }}
              layout="vertical"
              data={dataChartSpamRate}
              colors={colorsChartSpam}
              groupedWithTooltip
              enableGridY
              isGrouped
              innerPadding={5}
              markers
            />
          ),
        },
      ],
    ],
    [
      translate,
      emailsSentByISP,
      colorsChart,
      dataStacked,
      colorsStacked,
      nbDeliverabilityAlert,
      getContent,
      deliverabilityAlertContent,
      columns,
      perfByISPWithId,
      dataChartSpam,
      dataChartSpamRate,
      colorsChartSpam,
      noSpams,
      noResult,
      dateUse,
    ]
  );

  return {
    generalCards,
    performancesCards,
  };
};

export default useCards;
