import React from 'react';
import styled from '@emotion/styled';
import mindbazLogo from 'assets/mindbaz-logo-blanc.png';
import bgImage from 'assets/login-bg.jpg';
import { Text } from '@mindbaz/ui-v2';
import { useTranslation } from '@mindbaz/app-manager';

const Wrapper = styled('div')({
  backgroundColor: '#313B51',
  backgroundImage: `url(${bgImage})`,
  backgroundPosition: 'cente',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const MainContainer = styled('div')({
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
});

const IconMindbaz = styled('img')({
  width: 600,
  marginBottom: 40,
});

const AccessDenied = () => {
  const { translate } = useTranslation();

  return (
    <Wrapper>
      <MainContainer>
        <IconMindbaz alt="logo" src={mindbazLogo} />
        <Text variant="h1" color="secondary">
          {translate('accessDenied')}
        </Text>
      </MainContainer>
    </Wrapper>
  );
};

export default AccessDenied;
