import { styled } from '@mui/material';
import { Text, Button, colors } from '@mindbaz/ui-v2';

export const StyledHeader = styled('header')({
  backgroundColor: 'white',
  height: '4.5rem',
  display: 'flex',
  alignItems: 'center',
  padding: '0 1.25rem 0 1.25rem',
});

export const StyledImg = styled('img')({
  width: '100px',
  paddingTop: '0.4rem',
  paddingLeft: '0.3rem',
});

export const ContainerHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-around',
  padding: '12px 0',
});

export const ListNavigation = styled('li')({
  display: 'flex',
});

export const PointerCursor = styled('span')({
  cursor: 'pointer',
});

export const Navigation = styled('li')({
  margin: 'auto',
  listStyle: 'none',
  padding: '8px 20px',
});

export const ItemNavigation = styled('a')({
  textDecoration: 'none',
});

export const Banner = styled('div')({
  textAlign: 'center',
  backgroundColor: '#F554E526',
  padding: '8px',
});

export const ContainerCard = styled('div')({
  '&.dark': {
    background: 'linear-gradient(218.26deg, #11153F 22.05%, #0B0D15 70.38%)',
  },
  textAlign: 'center',
  borderRadius: '16px',
  margin: '24px 0',
  padding: '40px',
  border: '1px solid #EBEBEF',
  background: '#FFF',
  boxShadow: '0px 3px 15px 0px rgba(0, 0, 0, 0.04)',
});

export const Flex = styled('div')({
  display: 'flex',
  '&.mt-40': {
    marginTop: 40,
  },
  '&.gap-24': {
    gap: 24,
  },
  '&.justify-between': {
    justifyContent: 'space-between',
  },
  '&.justify-center': {
    justifyContent: 'center',
  },
  '&.align-center': {
    alignItems: 'center',
  },
  '&.pointer': {
    cursor: 'pointer',
  },
});

export const Label = styled('div')({
  '&.dark': {
    background: 'rgba(255, 255, 255, 0.15)',
    color: '#FFF',
  },
  width: 'fit-content',
  padding: '4px 8px',
  margin: ' 0 0 16px 0',
  borderRadius: '4px',
  background: 'rgba(34, 25, 69, 0.10)',
});

export const StyledButton = styled(Button)({
  display: 'flex',
  padding: '12px 24px',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: '0px 3px 15px 0px rgba(244, 56, 129, 0.20)',
  fontSize: '16px',
  fontWeight: 700,
});

export const ContainerCTA = styled('div')({
  display: 'flex',
  gap: 24,
  alignItems: 'center',
});

export const CustomText = styled('div')({
  '&.my-16': {
    margin: '16px 0',
  },
  '&.mb-16': {
    marginBottom: '16px',
  },
  '&.mb-48': {
    marginBottom: '48px',
  },
  '&.mt-48': {
    marginTop: '48px',
  },
  '&.mt-8': {
    marginTop: '8px',
  },
  '&.align-center': {
    textAlign: 'center',
  },
  '&.title': {
    '&> h1': {
      fontSize: '40px',
      maxWidth: 600,
      margin: 'auto',
      lineHeight: '48px',
    },
  },
  '&.subtitle': {
    '&> h6': {
      color: ' #595374',
      fontSize: '16px',
      lineHeight: '24px',
      maxWidth: 600,
      margin: 'auto',
    },
  },
  '&.title-card': {
    '&> h1': {
      fontSize: '32px',
      lineHeight: '40px',
      fontWeight: 700,
    },
  },
  '&.subtitle-card-default': {
    '&> h6': {
      fontSize: '16px',
      lineHeight: '24px',
      maxWidth: 600,
      margin: 'auto',
    },
  },
  '&.subtitle-card-dark': {
    '&> h6': {
      color: 'rgba(255, 255, 255, 0.75)',
      fontSize: '16px',
      lineHeight: '24px',
      maxWidth: 600,
      margin: 'auto',
    },
  },
});

export const BafflingProblem = styled('div')({
  '&.dark': {
    background: 'rgba(255, 255, 255, 0.10)',
  },
  '&.default': {
    background: '#FEF5F9',
  },
  display: 'flex',
  width: '528px',
  padding: '16px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
  borderRadius: '8px',
});

export const Goal = styled('div')({
  display: 'flex',
  width: '528px',
  padding: '16px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
  borderRadius: '8px',
  border: '2px solid rgba(137, 255, 212, 0.15)',
  background: 'rgba(137, 255, 212, 0.15)',
});

export const PanelFooter = styled('div')({
  display: 'flex',
  borderRadius: '16px',
  background: '#F4F3F6',
  padding: 40,
  marginBottom: 48,
  justifyContent: 'space-between',
});

export const LinkFooter = styled('div')({
  margin: '48px 0',
});

export const ContainerFeatures = styled('div')({
  maxWidth: 1160,
  margin: 'auto',
});

export const LearnMore = styled(Text)({
  fontSize: 16,
  fontWeight: 700,
});

export const PageFeatures = styled('div')({
  backgroundColor: 'white',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: 10,
});

export const SpanColor = styled('span')({
  color: 'black',
  fontWeight: 'bold',
});

export const SpanDiscover = styled('span')({
  color: '#F43881',
  fontWeight: 'bold',
  textDecoration: 'underline',
});

export const CustomLink = styled('a')({
  color: colors.DarkPink,
  fontSize: '16px',
  fontWeight: 700,
  margin: '0 8px',
});

export const Media = styled('div')({
  display: 'flex',
  width: 48,
  height: 48,
  justifyContent: 'center',
  alignItems: 'center',
  background: '#FFF',
  boxShadow: '0px 3px 15px 0px rgba(0, 0, 0, 0.04)',
  margin: '0 8px',
  borderRadius: 99,
  cursor: 'pointer',
  '&:hover': {
    background: 'rgba(0, 0, 0, 0.04)',
  },
});
