import React, { useCallback, useMemo } from 'react';
import { useTranslation, getCookie } from '@mindbaz/app-manager';
import { useQuery } from '@tanstack/react-query';

import { getDashboardsAccess } from 'packages/endpoints/access';
import TitleDescriptionBlock from 'components/common/TitleDescriptionBlock';
import Card from '../../components/common/Cards/Card';
import StyledNav from './styledComponents';
import { TCards } from './types';

const Dashboards = () => {
  const { translate } = useTranslation();

  const { data: dashboardsAccess } = useQuery(['dashboards-access'], () =>
    getDashboardsAccess()
  );

  if (!dashboardsAccess.userHasAccess) {
    window.location.replace(`${process.env.REACT_APP_RUBIXO_URL}accessdenied`);
  }

  const CARD_TYPE: TCards[] = useMemo(
    () => [
      {
        typeName: 'deliverabilityReport',
        link: process.env.REACT_APP_DELIVERABILITY_IMPORT_LINK,
        content: translate('dashboards.menuCards.deliverability.content'),
        titleIcon: 'mark_email_read',
        titleText: translate('dashboards.menuCards.deliverability.title'),
      },
      {
        typeName: 'campaignStatistics',
        link: process.env.REACT_APP_CAMPAIGN_STATISTIC_LINK,
        content: translate('dashboards.menuCards.campaign.content'),
        titleIcon: 'insert_chart',
        titleText: translate('dashboards.menuCards.campaign.title'),
      },
    ],
    [translate]
  );

  const handleCardClick = useCallback(
    (type: string) => {
      const token = getCookie('at');
      const card = CARD_TYPE.find((c) => type === c.typeName);
      if (card && card.typeName === 'dailyReport') {
        window.location.href = `${card.link}`;
      } else {
        window.location.replace(`${card?.link}${token}`);
      }
    },
    [CARD_TYPE]
  );

  return (
    <>
      <TitleDescriptionBlock
        title="dashboards.welcome"
        description="dashboards.presentation"
      />
      <StyledNav>
        {CARD_TYPE.map(
          ({ typeName, content, titleIcon, titleText }: TCards) => (
            <div key={typeName} onClick={() => handleCardClick(typeName)}>
              <Card
                Content={content}
                titleIcon={titleIcon}
                titleText={titleText}
              />
            </div>
          )
        )}
      </StyledNav>
    </>
  );
};
export default Dashboards;
