import React, { useCallback, useMemo } from 'react';
import { useTranslation } from '@mindbaz/app-manager';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import { TRate } from './types';
import { TextCustom } from './styledComponents';

const rates = {
  sent: {
    max: 95,
    min: 80,
  },
  deliveredEmails: {
    max: 95,
    min: 80,
  },
  hardBounce: {
    weSearchMinimum: true,
    max: 3,
    min: 1,
  },
  openers: {
    max: 5,
    min: 2,
  },
  clickers: { max: 2, min: 1 },
  unsubscribes: { max: 4, min: 2 },
};

const abnormalRates: ('warning' | 'error')[] = ['warning', 'error'];

const useDataGrid = () => {
  const { translate } = useTranslation();

  const rateColor = useCallback((rate: number, ratesExtremity: TRate) => {
    if (rate > ratesExtremity.max)
      return ratesExtremity.weSearchMinimum ? abnormalRates[1] : 'primary';
    if (rate < ratesExtremity.min)
      return ratesExtremity.weSearchMinimum ? 'primary' : abnormalRates[1];
    return abnormalRates[0];
  }, []);

  // The threshold for unsubscribe data is reverse compare to others.
  const rateColorReverse = useCallback(
    (rate: number, ratesExtremity: TRate) => {
      if (rate < ratesExtremity.max)
        return ratesExtremity.weSearchMinimum ? abnormalRates[1] : 'primary';
      if (rate < ratesExtremity.min)
        return ratesExtremity.weSearchMinimum ? 'primary' : abnormalRates[1];
      return abnormalRates[0];
    },
    []
  );

  const isAbnormalRate = useCallback(
    (rate: number, ratesExtremity: TRate, shouldRateColorBeReverse = false) => {
      return abnormalRates.find(
        (abnormalRate) =>
          abnormalRate ===
          (shouldRateColorBeReverse
            ? rateColorReverse(rate, ratesExtremity)
            : rateColor(rate, ratesExtremity))
      );
    },
    [rateColor, rateColorReverse]
  );

  const getTextData = useCallback(
    (rate: number, ratesExtremity: TRate, shouldRateColorBeReverse = false) => {
      return (
        <div>
          <TextCustom
            className={
              isAbnormalRate(rate, ratesExtremity, shouldRateColorBeReverse) &&
              'bold'
            }
            color={
              shouldRateColorBeReverse
                ? rateColorReverse(rate, ratesExtremity)
                : rateColor(rate, ratesExtremity)
            }
          >
            {rate.toFixed(1)}%
          </TextCustom>
        </div>
      );
    },
    [isAbnormalRate, rateColor, rateColorReverse]
  );

  const columns = useMemo(
    () => [
      {
        field: 'yLabel',
        flex: 0.8,
        headerName: '',
      },
      {
        field: 'sendingPct',
        flex: 0.8,
        headerName: translate(
          'dailyReport.tabs.gridPerformances.header.sentRate'
        ),
        renderCell: (params: GridRenderCellParams<string>) =>
          getTextData(params.row.sendingPct, rates.sent),
      },
      {
        field: 'deliveredPct',
        flex: 0.8,
        headerName: translate(
          'dailyReport.tabs.gridPerformances.header.deliveredEmails'
        ),
        renderCell: (params: GridRenderCellParams<string>) =>
          getTextData(params.row.deliveredPct, rates.deliveredEmails),
      },
      {
        field: 'hardBouncePct',
        flex: 0.8,
        headerName: translate(
          'dailyReport.tabs.gridPerformances.header.hardBounce'
        ),
        renderCell: (params: GridRenderCellParams<string>) =>
          getTextData(params.row.hardBouncePct, rates.hardBounce),
      },

      {
        field: 'openersPct',
        flex: 0.8,
        headerName: translate(
          'dailyReport.tabs.gridPerformances.header.openers'
        ),
        renderCell: (params: GridRenderCellParams<string>) =>
          getTextData(params.row.openersPct, rates.openers),
      },
      {
        field: 'clickersPct',
        flex: 0.8,
        headerName: translate(
          'dailyReport.tabs.gridPerformances.header.clickers'
        ),
        renderCell: (params: GridRenderCellParams<string>) =>
          getTextData(params.row.clickersPct, rates.clickers),
      },
      {
        field: 'unsubClickersPct',
        flex: 0.8,
        headerName: translate(
          'dailyReport.tabs.gridPerformances.header.unsubscribes'
        ),
        renderCell: (params: GridRenderCellParams<string>) =>
          getTextData(params.row.unsubClickersPct, rates.unsubscribes, true),
      },
    ],
    [translate, getTextData]
  );

  return {
    columns,
  };
};

export default useDataGrid;
