import React, { useCallback } from 'react';
import { Icon, Text } from '@mindbaz/ui-v2';
import { useTranslation } from '@mindbaz/app-manager';
import {
  CustomLink,
  CustomText,
  Flex,
  LinkFooter,
  Media,
  PanelFooter,
} from 'pages/Features/styledComponents';

import twitter from 'assets/twitter.svg';
import linkedin from 'assets/linkedin.svg';

const MEDIA = {
  TWITTER: { label: 'twitter', url: 'https://twitter.com/' },
  LINKEDIN: { label: 'linkedin', url: 'https://linkedin.com/' },
};

const Footer = () => {
  const { translate } = useTranslation();

  const handleRedirection = useCallback((media: string) => {
    if (media === MEDIA.TWITTER.label) window.open(MEDIA.TWITTER.url, '_blank');
    if (media === MEDIA.LINKEDIN.label)
      window.open(MEDIA.LINKEDIN.url, '_blank');
  }, []);

  return (
    <div>
      <PanelFooter>
        <div>
          <Text variant="h1">
            {translate('features.footer.featuresInProgress')}
          </Text>
          <Text variant="subtitle">{translate('features.footer.follow')}</Text>
        </div>
        <Flex>
          <Media
            onClick={() => {
              handleRedirection('linkedin');
            }}
          >
            <img src={linkedin} alt="linkedin" />
          </Media>

          <Media
            onClick={() => {
              handleRedirection('twitter');
            }}
          >
            <img src={twitter} alt="twitter" />
          </Media>
        </Flex>
      </PanelFooter>
      <LinkFooter>
        <Text align="center">{translate('features.footer.optimizeEmail')}</Text>
        <CustomText className="align-center mt-8">
          <CustomLink
            href={translate('features.footer.followEmailTrainingLink')}
            target="_blank"
          >
            {translate('features.footer.followEmailTraining')}
          </CustomLink>
          <Icon color="light" size={4}>
            circle
          </Icon>
          <CustomLink
            href={translate('features.footer.learnMoreLink')}
            target="_blank"
          >
            {translate('features.footer.learnMore')}
          </CustomLink>
        </CustomText>
      </LinkFooter>
    </div>
  );
};

export default Footer;
